import './App.css';
import AppHeader from "./AppHeader";
import AppForm from "./AppForm";
import AppTodoList from "./AppTodoList";
import AppExportModal from "./AppExportModal";
import { useState, useEffect } from "react";
import {getTodos, saveTodos} from "../utils/storage";
import { exportToCSV, exportToJSON, exportToXML, base64ToArrayBuffer } from "../utils/export";

function App() {
    const [todos, setTodos] = useState([]);
    const[isExporting, setIsExporting] = useState(false);

    const addToDo = text => {
        const id = Math.random().toString().substring(2);
        const now = new Date().toISOString();
        const date = now.replace(/\.[a-z0-9]+$/gi,'').split('T').join(' ');
        const todo = { id, date, text };
        setTodos(prevState => {
            const updatedTodos = [todo, ...prevState];
            saveTodos(updatedTodos);
            return updatedTodos;
        });

    };

    const removeTodo = id => {
        setTodos(prevState => {
            const updatedTodos = prevState.filter(todo => todo.id !== id);
            saveTodos(updatedTodos);
            return updatedTodos;
        });
    };

    const exportToDos = type => {
        let output = '';
        let isTopFramePolicy = false;
        switch(type) {
            case 'csv':
                output = exportToCSV(todos);
                break;
            case 'json':
                output = exportToJSON(todos);
                isTopFramePolicy = true;
                break;
            case 'xml':
                output = exportToXML(todos);
                isTopFramePolicy = true;
                break;
            default:
                break;
        }
        if(output.length === 0) {
            return false;
        }
        if(!isTopFramePolicy) {
            window.location.href = encodeURI(output);
        } else {
            const byte = base64ToArrayBuffer(output);
            const contentType = type === 'json' ? 'application/json' : 'text/xml';
            const blob = new Blob([byte], {type: contentType});
            document.location.replace(URL.createObjectURL(blob));
        }
        return true;
    };

    useEffect(() => {
        setTodos(getTodos());
    }, []);

  return (
    <main className="app">
        {isExporting && <AppExportModal exportToDos={exportToDos} setIsExporting={setIsExporting}/>}
        <AppHeader todos={todos} setIsExporting={setIsExporting}/>
        <AppForm addToDo={addToDo} />
        {todos.length > 0 && <AppTodoList todos={todos} remove={removeTodo} /> }
    </main>
  );
}

export default App;
