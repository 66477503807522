export function base64ToArrayBuffer(base64Str) {
    const binaryString = window.atob(base64Str);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

export function exportToCSV(todos) {
    let csv = 'data:text/csv;charset=utf-8,';
    let rows = [['Date','Text']];
    for(const todo of todos) {
        const { text, date } = todo;
        rows.push([date, text]);
    }
    return csv + rows.map(row => row.join(',')).join('\n');
}

export function exportToJSON(todos) {
    return window.btoa(JSON.stringify(todos.map(todo => {
        const { text, date } = todo;
        return {
            text, date
        };
    })));
}

export function exportToXML(todos) {
    let xml = ['<?xml version="1.0" encoding="UTF-8" ?>', '<todos>'];
    for(const todo of todos) {
        const { date, text } = todo;
        const fragment = `
            <todo>
                <date>${date}</date>
                <text>${text}</text>
            </todo>
        `;
        xml.push(fragment);
    }
    xml.push('</todos>');
    return window.btoa(xml.join('\n'));
}