function createKeySuffix() {
    const url = new URL(window.location.href);
    return window.btoa(url.hostname).replace(/[^a-z0-9]+/gi, '');
}
export function getTodos() {
    const todos = localStorage.getItem('app-todos-' + createKeySuffix());
    return todos !== null ? JSON.parse(todos) : [];
}

export function saveTodos(todos) {
    return localStorage.setItem('app-todos-' + createKeySuffix(), JSON.stringify(todos));
}