import './AppExportModal.css';
import { useState} from "react";

function AppExportModal({ setIsExporting, exportToDos }) {
    const [type, setType] = useState('csv');
    return (
        <div className="app-export-modal">
            <button className="app-export-modal-close" onClick={() => setIsExporting(false)}>&times;</button>
            <form className="app-export-modal-form" onSubmit={(e) => e.preventDefault()}>
                <div>
                    <select defaultValue={type} onChange={(e) => setType(e.target.value)}>
                        <option value="csv">CSV</option>
                        <option value="json">JSON</option>
                        <option value="xml">XML</option>
                    </select>
                    <button onClick={() => exportToDos(type)}>Export</button>
                </div>
            </form>
        </div>
    )
}

export default AppExportModal;