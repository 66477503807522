import './AppForm.css';
import { useState } from "react";

function AppForm({ addToDo }) {
    const [text, setText] = useState('')
    const handleSubmit = e => {
        e.preventDefault();
        addToDo(text);
        setText('');
    };
    return (
        <form method="post" className="app-form" onSubmit={(e) => handleSubmit(e)}>
            <div>
                <textarea value={text} onChange={(e) => setText(e.target.value)}></textarea>
                <button type="submit">Add To-Do</button>
            </div>
        </form>
    );
}

export default AppForm;