import './AppHeader.css';
function AppHeader({ title, todos, setIsExporting }) {
    return (
        <header className="app-header">
            <h1>{title || 'To-Do List'}</h1>
            {todos.length > 0 && <button onClick={() => setIsExporting(true)}>Export List</button>}
        </header>
    );
}

export default AppHeader;