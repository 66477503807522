import './AppToDo.css';
function AppToDo({ todo, remove }) {
    return (
        <li className="app-to-do">
            <time>{todo.date}</time>
            <p>{todo.text}</p>
            <button onClick={() => remove(todo.id)}>&times;</button>
        </li>
    )
}

export default AppToDo;