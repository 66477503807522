import './AppTodoList.css';
import AppToDo from "./AppToDo";
function AppTodoList({ todos, remove }) {
    return (
        <ul className="app-todo-list">
            {todos.map((todo) => (
                <AppToDo key={todo.id} todo={todo} remove={remove} />
            ))}
        </ul>
    );
}

export default AppTodoList;